import React, {useState, useEffect} from 'react';
import UserInfoTable from './UserInfoTable';
import UserDecodersList from './UserDecodersList';
import './style.css'
import Pagination from '../components/Pagination';
import Payments from './../Payments/Payments';
import { fetchSubscribersData } from './../service/subscribers/comp';
import { two } from './../service/rights/two';
import { fetchPaymentsData } from './../service/payments/comp';
const columns = [{ id: 'email', label: 'Email', }, { id: 'name', label: 'User Name', }, { id: 'phone', label: 'Phone', }];   
const Subscribers = ({domain, dataL, setDataL, selfRightsLvl, currenciesL}) => {
    const [subscribersData, setSubscribersData] = useState(dataL);
    const [pageNumber, setPageNumber] = useState(0);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [info, setShowInfo] = useState(false);
    const [showPayments, setShowPayments] = useState(false);
    const [payments, setPayments] = useState({});
    const [balance, setBalance] = useState(0);
    const getPayments = async (userEmail) => {
      fetchPaymentsData(userEmail, null, null, 0, setPayments, domain)
    }
    const paymentsState = () => {
      setShowPayments(!showPayments);
    }
    const [user, setUserInfo] = useState({});    
    const [userId, setUserId] = useState(0);

    ////////////////
    // Fetching functions     
    const fetchUser = async (index) => {
      try{
        const response = await fetch(`https://${domain}/api/user/${index}`);
        if(!response.ok) throw new Error()
        const data = await response.json();
        setUserInfo(data || {});
        setUserId(index);
      }catch(e){
        console.error(e.message)
      }
    }
    ////////////////
    // page loading 
    const pageCount = Math.ceil(subscribersData?.count !== null ? subscribersData.count / 10 : 0);
    const displayData = subscribersData.users && subscribersData.users.map(row => (
      <tr key={row.id} onClick={() => {handleShowUserInfo(row.id, row.email)}}>
        {columns.map(column => <td key={column.id}> {row[column.id] !== '' ?  row[column.id] : 'None'} </td>)}
      </tr>
    ));

    ////////////////
    // actions
    const fillForms = (param, value) => {
      switch (param) {
        case 'name':
          setName(value);
          break;
        case 'email':
          setEmail(value);
          break;
        case 'phone':
          setPhone(value);
          break;
        default:
          break;
      }
    }
    const handleShowUserInfo = (index, userEmail) => { 
      setShowInfo(true);
      setShowPayments(false)
      getPayments(userEmail);
      fetchUser(index);
    }

    const fetchList = () => {
      fetchSubscribersData(name, email, phone, pageNumber, setSubscribersData, domain);
    }

    useEffect(() =>{
      fetchList();
      setShowInfo(false);
    }, [pageNumber])
    
    useEffect(() =>{
      setDataL(subscribersData);
    }, [subscribersData])

    return(
      <div className="subscribers subscribers-box box"> 
        <button onClick={fetchList} className='requestBtn load-btns'>Get Filtered list</button>
        <div>
        {/* className='users-decoder' */}
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                    <th key={column.id}>
                      <div className='input-areas' style={{textAlign: "left"}}>
                      <label>{column.label}</label>
                      <input
                      placeholder={`Find by ${column.label}`}
                      className='inputs' type="text"
                      onChange={(e) => {fillForms(column.id, e.target.value)}}/>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>  
          </table>
          <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={(param) => {setPageNumber(param)}}/> 
        </div>
        {info && <UserInfoTable userData={user} newBalance={balance}/>} 
        {info && <UserDecodersList decoders={user.decoders} />} 
        {info && (selfRightsLvl < 32 ? two(selfRightsLvl) : true) && <button onClick={paymentsState} className='requestBtn load-btns'>{!showPayments ? 'Show payments' : 'Close'}</button> }
        {showPayments && <Payments setBalance={setBalance} domain={domain}  selfRightsLvl={selfRightsLvl} email={user.email || ''} dataL={payments} setDataL={setPayments} id={userId} currencies={currenciesL}/>}
      </div>
    )
}


export default Subscribers;