import React from 'react';
import { Line } from 'react-chartjs-2';
// Import necessary Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({services}) => {

  // Define data and options
  const data = React.useMemo(() => ({
    labels: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
    datasets: [
      {
        label: 'Decoders used',
        data: services.map(service => service.decoders || 0),
        borderColor: 'rgba(255,99,132,1)',
        backgroundColor: 'rgba(255,99,132,0.2)',
        tension: 0.4,
      },
      {
        label: 'Deactivated decoders',
        data: services.map(service => service.deactivated || 0),
        borderColor: 'rgba(255, 140, 0, 1)',
        backgroundColor: 'rgba(255,99,132,0.2)',
        tension: 0.4,
      }
    ],
  }), [services]);
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: 'Decoders used',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide X-axis grid lines
        },
      },
      y: {
        ticks: {
          stepSize: 20, // Increment steps
        },
      },
    },
  };
  

  return <Line data={data} options={options} />;
};

export default LineChart;