import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({services}) => {

  // Define data and options
  const data = React.useMemo(() => ({
    labels: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
    datasets: [
      {
        label: 'Services',
        data: services.map(service => service.services || 0), // Обработка undefined
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        tension: 0.4,
      },
      {
        label: 'Payments',
        data: services.map(service => service.payments || 0),
        borderColor: 'rgba(54,162,235,1)',
        backgroundColor: 'rgba(54,162,235,0.2)',
        tension: 0.4,
      },
    ],
  }), [services]);
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: 'Monthly Sales Data',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide X-axis grid lines
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            return value; // Добавление знака $ к меткам
          },
          stepSize: 20, // Increment steps
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Domestic Currency', // Название оси
          font: {
            size: 16,
          },
        },
      },
    },
  };
  
  return <Line data={data} options={options} />;
};

export default LineChart;