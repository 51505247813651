export const fetchList = async (email, from_date, to_date, pageNumber, domain) => {
    const requestBody = {
      email: email, 
      from_date: from_date,
	    to_date: to_date,
      limit: 10,
      offset: pageNumber * 10
    }
    const requestParams = {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    try{
      const response = await fetch(`https://${domain}/api/payments/list`, requestParams);
      if (!response.ok){
        throw new Error();
      }
      const data = await response.json();
      return data;
    }catch(e){
      console.error(e.message);
      return {};
    }
  }
  