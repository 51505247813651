import React, { useEffect, useState } from 'react';
import Info from './Info';
import Downloaders from './Downloaders';
import Loading from '../components/Loading';
import icon from './excel.png';
import LineChart from './LineChart';
import './style.css';
import Line2 from './Line2';
import {fetchSummary} from '../service/reports/Total';
const Reports = ({domain}) => {
  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return false;
    }
    return true;
  }
  const [services, setServices] = useState([]);
  const yearFetch = async () => {
    setServices([]); // Сброс данных перед загрузкой
    setLeadingPro(true); // Показать загрузочный экран
  
    const promises = [];
    
    for (let i = 1; i <= 12; i++) {
      const startDate = `${year}-${i < 10 ? "0" + i : i}-01`;
      const endDate = `${i < 11 ? year : year + 1}-${month(i)}-01`;
  
      // Добавляем запрос в массив промисов
      const fetchPromise = fetchSummary(startDate, endDate, domain).then((response) => ({
        index: i - 1,
        data: response,
      }));
      promises.push(fetchPromise);
    }
  
    try {
      // Ждём завершения всех запросов
      const results = await Promise.all(promises);
  
      // Собираем данные в массив в правильном порядке
      const updatedServices = [];
      results.forEach(({ index, data }) => {
        updatedServices[index] = data;
      });
  
      // Устанавливаем готовый массив данных
      setServices(updatedServices);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      // Скрываем загрузочный экран
      setLeadingPro(false);
    }
  };
  
  const month = (i) => {
    if(i + 1 < 10){
      return "0" + (i + 1)
    }else if(i < 12){
      return i + 1;
    }else{
      return "01";
    }
  }
  const fetchList = async () => {
    setLeadingPro(true);
    try{
      if(isValidDate(first) && isValidDate(last)) {
        setErrorModal(false);
        const responce = await fetchSummary(first, last, domain);
        setSummary(responce);
      } else{
        setErrorModal(true);
      }
      setLeadingPro(false);
    }catch(e){ console.error(e);}

  } 

  const [summary, setSummary] = useState({});
  const [first,setFirst] = useState('');
  const [last,setLast] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [modal, setModal] = useState(false);
  
  const setLeadingPro = (state) => {
    if(state) window.scrollTo(0, 0);
    setModal(state);
  }
  
  const handleYearChange = (e) => {
    setYear(parseInt(e.target.value, 10));
  }
  const handleFirstChange = (e) => {
    setFirst(e.target.value);
  }
  const handleLastChange = (e) => {
    setLast(e.target.value);
  }
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const fetchTable = async (name) => {
      const requestBody = {
        from_date: first, 
        to_date: last
      }
      const requestParams = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      setLeadingPro(true);
      
      try{
        const response = await fetch(`https://${domain}/api/report/${name}`, requestParams);
        if (!response.ok){
          throw new Error();
        }
        setErrorModal(false);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        
      }catch(e){
        console.error(e.message);
        setErrorModal(true);
      }finally{
        setLeadingPro(false);
      }
  }
  const fetchLastTable = async () => {
    try{
      const response = await fetch(`https://${domain}/api/report/zero-cost`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });
      if (!response.ok){
        throw new Error()
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'report.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }catch(e){
      console.error(e.message);
    }
  }
  return(
        <div className='report-box'>
            <div className='reportsHeader'>
                <div className='input-areas'>
                  <label>Year</label>
                  <input placeholder='XXXX'
                  onChange={handleYearChange}
                  className='inputs' type="number"/>
                </div>
                <button onClick={yearFetch} className='requestBtn padBtn'>Submit</button>
            </div>
            <div style={{display:"flex"}}>
              <div style={{width: "45%", margin: "5px"}}> <LineChart services={services}/></div>
              <div style={{width: "45%", margin: "5px"}}><Line2 services={services}/></div>
            </div>
            <div className='reportsHeader'>
            <div className='input-areas'>
                  <label>First date</label>
                  <input placeholder='XXXX-XX-XX'
                  onChange={handleFirstChange}
                  className='inputs' type="text"/>
                </div>
                <div className='input-areas'>
                  <label>Second date</label>
                  <input placeholder='XXXX-XX-XX'
                  onChange={handleLastChange}
                  className='inputs' type="text"/>
                </div>
                <button onClick={fetchList} className='requestBtn padBtn'>Submit</button>
                {errorModal && <span className='red'>Incorrect date</span>}
            </div>
            <Info summary={summary}/>
            <Downloaders fetchTable={fetchTable} fetchLastTable={fetchLastTable} icon={icon}/>
            {modal && <Loading/>}
        </div>
    )
}

export default Reports;