const UserDecodersList = ({decoders}) => {
    const converter = (param) => {
        if (!param.expire) return "Inactive";
        return param.expire
    }
    return(
        <div className="users-decoders-grid">
            {decoders && decoders.map((decoder, decIndex) => (
                <div key={decIndex} className="users-decoder">
                    <div>
                        <div>Decoder id: <span className="pointed">{decoder.decoder_id}</span></div>
                    </div>
                    <table>
                        <thead><tr style={{textAlign:'center'}}><th colSpan={4}>Packages:</th></tr></thead>
                        <tbody>
                          {decoder.packs && decoder.packs.map((pack, packIndex) => (
                              <tr className="decoders-list-row" key={packIndex}><td>{pack.name}</td>
                                  <td><span className="pointed">{pack.cost}</span> units per 30 days</td>
                                  <td>Date of {pack.one_time ? ' expiration:' :  ' next activation:'} <span className="pointed">{converter(pack)}</span></td>
                              </tr>   
                          ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    )
}

export default UserDecodersList;