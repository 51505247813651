import EditBtn from './EditBtn';
import AddMod from './Modal';
import Uploader from './Uploader';  
import React, {useState, useEffect} from 'react';
import Pagination from '../components/Pagination';
import { fetchDecodersData } from '../service/decoders/comp';
import { fetchTypes } from '../service/types/comp';
const columns = [
  { id: 'decoder_id', label: 'Decoder number' },
  { id: 'type', label: 'Type of package' },
  { id: 'created', label: 'Date of Creating' },
  { id: 'btn', label: '' }
];

const Decoders = ({domain, selfRightsLvl, dataL, setDataL}) => {
    const [decoders, setDecoders] = useState(dataL);
    const [decId, setId] = useState(null);

    const [types, setTypes] = useState([]);

    const getTypes = async () => {
      fetchTypes(setTypes, domain)
    } 

    const fetchList = async () => {
      fetchDecodersData(decId, pageNumber, setDecoders, domain);
    }

    ////////////////
    // page loading 
    const [pageNumber, setPageNumber] = useState(0);
    
    const pageCount = Math.ceil(decoders.count !== undefined ? decoders.count / 10 : 0);

    const styler = (id) => {
      if (id === 'btn') return selfRightsLvl >= 32 ? {padding: 1.5, border: 0, width: '5%'} : {display: 'none'}
      else return {padding: 'auto'}
    }

    const displayData = decoders.decoders && decoders.decoders.map((row, index) => (
      <tr key={row.id}>
        {columns.map(column => 
          <td key={column.id} style={styler(column.id)}> 
            {
              column.id !== 'btn' ?
                (row[column.id] !== '' ?
                  row[column.id] 
                : 
                  'None'
                ) 
              : <EditBtn 
                decId={decId} 
                pageNumber={pageNumber} 
                setDecoders={setDecoders} 
                fetchDecodersData={fetchDecodersData} 
                domain={domain} 
                id={decoders.decoders[index].decoder_id}
                type={decoders.decoders[index].type}
                types={types}
                fetchTypesAgain={setTypes}
                />
            }
          </td>
        )}
      </tr>
    ));

    ////////////////
    // actions
    const fillForms = (e) => {
      setId(e.target.value === "" ? null : e.target.value);
    }
    useEffect(() =>{
      fetchList();
      getTypes();
    }, [pageNumber])

    useEffect(() => {
      setDataL(decoders)
    }, [decoders])

    return(
      <div className="packages box"> 
        <input type="text" className='textField' placeholder='Find by decoder id' onChange={fillForms}/>
        <button 
        onClick={fetchList} 
        className='requestBtn'>Get Filtered list</button>
        <div className='op-table'>
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                  <th key={column.id} style={styler(column.id)}>
                    {column.label}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>
          </table>
          <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber}/> 
        </div>
        <AddMod selfRightsLvl={selfRightsLvl} fetchAgain={fetchList} domain={domain} />
        <Uploader selfRightsLvl={selfRightsLvl} fetchAgain={fetchList} domain={domain}/>
      </div>
    )
}
export default Decoders;